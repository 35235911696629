p {
  margin: 0;
}

a {
  color: #2d2d2c;
}
a:hover {
  color: #a7a7a7;
  text-decoration: none;
}

h3 {
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  padding-top: 32px;
}

h4 {
  font-weight: 400;
  margin: 0;
  padding-top: 32px;
  padding-bottom: 12px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}

.divider {
  border-top: 2px solid #eef1f2;
  margin: 16px 0;
}

.btn {
  margin: 10px;
}
.btn.table-btn {
  margin: 0px;
  color: #1b75bb;
  border-color: #1b75bb;
}
.btn.table-btn:hover {
  color: #ffffff;
  background-color: #1b75bb;
  border-color: #1b75bb;
}

.btn-outline-primary {
  color: #1b75bb;
  border-color: #1b75bb;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #1b75bb;
  border-color: #1b75bb;
}

.btn-outline-warning:hover {
  color: #ffffff;
}

.hide {
  visibility: hidden;
}
.hide .btn {
  visibility: visible;
}

.btn-google {
  position: relative;
  top: -10px;
  padding: 0;
}

.btn-people {
  width: 223px;
}

.btn-logout {
  width: 223px;
  position: relative;
  top: -10px;
}

.btn-container-home {
  position: relative;
  left: 40px;
  top: 30px;
}

.btn-container-profile {
  text-align: center;
  position: relative;
  top: 15px;
}
.btn-container-profile .btn {
  width: 148px;
}

.Content {
  padding: 32px 0px;
}

.footer {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: column, column, row;
          flex-direction: column, column, row;
  margin-bottom: 16px;
  font-weight: 500;
}

.footer-container {
  margin-top: auto;
}

.form .form-hello {
  text-align: center;
  font-size: 32px;
  margin-bottom: 10px;
}
.form .edit-form .form-group label {
  font-size: 22px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.navbar {
  position: relative;
  top: 0;
  width: 100%;
  -ms-flex-line-pack: left;
      align-content: left;
  font-size: 20px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: -8px;
}
.navbar .navbar-brand {
  padding: 0;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.navbar .navbar-brand:hover, .navbar .navbar-brand:active, .navbar .navbar-brand:focus {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.navbar .navbar-content {
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.navbar .navbar-content .navbar-content-item {
  padding: 0 10px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.navbar .navbar-content .navbar-content-item:hover, .navbar .navbar-content .navbar-content-item:active, .navbar .navbar-content .navbar-content-item:focus {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.navbar .navbar-content .navbar-content-item .navbar-content-link {
  letter-spacing: 1.5px;
}

.Table {
  width: 100%;
  padding-bottom: 32px;
}
.Table th,
.Table td {
  padding: 15px;
  text-align: left;
}
.Table tr:nth-child(even) {
  background-color: #d2e8f9;
}
.Table .table-button {
  font-size: 30px;
}
.Table .table-button:hover {
  color: #1b75bb;
  text-decoration: none;
}

.input-group .form-control {
  border-color: #1b75bb;
}

.home {
  margin: 32px 0;
}
.home .home-heading {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1px;
}
.home .home-text {
  line-height: 1.75;
  margin: 20px 0;
  font-size: 16px;
}
.home .home-img {
  width: 140px;
}
.home .wid-logo {
  height: 90px;
  position: relative;
  top: -5px;
}

.page-not-found.Content {
  padding: 0px;
}
.page-not-found body {
  margin: 0;
  font-size: 20px;
}
.page-not-found * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page-not-found .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
  background: white;
  color: #1b75bb;
  font-family: arial, sans-serif;
  overflow: hidden;
}
.page-not-found .content {
  position: relative;
  width: 600px;
  max-width: 100%;
  margin: 20px;
  background: white;
  padding: 40px 20px;
  text-align: center;
  -webkit-box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2);
          box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995) forwards;
          animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995) forwards;
}
.page-not-found .content p {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
  color: #595959;
}
.page-not-found .content p:last-child {
  margin-bottom: 0;
}
.page-not-found .content button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  border: 3px solid #595959;
  background: transparent;
  font-size: 1rem;
  color: #595959;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}
.page-not-found .particle {
  position: absolute;
  display: block;
  pointer-events: none;
}
.page-not-found .particle:nth-child(1) {
  top: 75.2136752137%;
  left: 57.8999018646%;
  font-size: 19px;
  -webkit-filter: blur(0.02px);
          filter: blur(0.02px);
  -webkit-animation: 23s floatReverse2 infinite;
          animation: 23s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(2) {
  top: 74.9691738594%;
  left: 32.6409495549%;
  font-size: 11px;
  -webkit-filter: blur(0.04px);
          filter: blur(0.04px);
  -webkit-animation: 33s float2 infinite;
          animation: 33s float2 infinite;
}
.page-not-found .particle:nth-child(3) {
  top: 20.4628501827%;
  left: 13.7120470127%;
  font-size: 21px;
  -webkit-filter: blur(0.06px);
          filter: blur(0.06px);
  -webkit-animation: 21s floatReverse2 infinite;
          animation: 21s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(4) {
  top: 77.7642770352%;
  left: 84.0664711632%;
  font-size: 23px;
  -webkit-filter: blur(0.08px);
          filter: blur(0.08px);
  -webkit-animation: 23s float infinite;
          animation: 23s float infinite;
}
.page-not-found .particle:nth-child(5) {
  top: 38.4710234279%;
  left: 93.9663699308%;
  font-size: 11px;
  -webkit-filter: blur(0.1px);
          filter: blur(0.1px);
  -webkit-animation: 32s floatReverse infinite;
          animation: 32s floatReverse infinite;
}
.page-not-found .particle:nth-child(6) {
  top: 78.1440781441%;
  left: 42.1982335623%;
  font-size: 19px;
  -webkit-filter: blur(0.12px);
          filter: blur(0.12px);
  -webkit-animation: 36s float infinite;
          animation: 36s float infinite;
}
.page-not-found .particle:nth-child(7) {
  top: 18.5365853659%;
  left: 41.1764705882%;
  font-size: 20px;
  -webkit-filter: blur(0.14px);
          filter: blur(0.14px);
  -webkit-animation: 29s float infinite;
          animation: 29s float infinite;
}
.page-not-found .particle:nth-child(8) {
  top: 92.3832923833%;
  left: 24.6548323471%;
  font-size: 14px;
  -webkit-filter: blur(0.16px);
          filter: blur(0.16px);
  -webkit-animation: 22s floatReverse infinite;
          animation: 22s floatReverse infinite;
}
.page-not-found .particle:nth-child(9) {
  top: 7.7858880779%;
  left: 49.9021526419%;
  font-size: 22px;
  -webkit-filter: blur(0.18px);
          filter: blur(0.18px);
  -webkit-animation: 29s float infinite;
          animation: 29s float infinite;
}
.page-not-found .particle:nth-child(10) {
  top: 67.0716889429%;
  left: 60.6060606061%;
  font-size: 23px;
  -webkit-filter: blur(0.2px);
          filter: blur(0.2px);
  -webkit-animation: 39s floatReverse infinite;
          animation: 39s floatReverse infinite;
}
.page-not-found .particle:nth-child(11) {
  top: 80.5825242718%;
  left: 75.1953125%;
  font-size: 24px;
  -webkit-filter: blur(0.22px);
          filter: blur(0.22px);
  -webkit-animation: 24s floatReverse infinite;
          animation: 24s floatReverse infinite;
}
.page-not-found .particle:nth-child(12) {
  top: 91.5129151292%;
  left: 63.1786771964%;
  font-size: 13px;
  -webkit-filter: blur(0.24px);
          filter: blur(0.24px);
  -webkit-animation: 29s float infinite;
          animation: 29s float infinite;
}
.page-not-found .particle:nth-child(13) {
  top: 9.6618357488%;
  left: 5.8365758755%;
  font-size: 28px;
  -webkit-filter: blur(0.26px);
          filter: blur(0.26px);
  -webkit-animation: 23s float infinite;
          animation: 23s float infinite;
}
.page-not-found .particle:nth-child(14) {
  top: 15.763546798%;
  left: 8.8932806324%;
  font-size: 12px;
  -webkit-filter: blur(0.28px);
          filter: blur(0.28px);
  -webkit-animation: 26s floatReverse infinite;
          animation: 26s floatReverse infinite;
}
.page-not-found .particle:nth-child(15) {
  top: 28.2582216809%;
  left: 96.9637610186%;
  font-size: 21px;
  -webkit-filter: blur(0.3px);
          filter: blur(0.3px);
  -webkit-animation: 25s float2 infinite;
          animation: 25s float2 infinite;
}
.page-not-found .particle:nth-child(16) {
  top: 13.7086903305%;
  left: 30.4818092429%;
  font-size: 17px;
  -webkit-filter: blur(0.32px);
          filter: blur(0.32px);
  -webkit-animation: 29s float infinite;
          animation: 29s float infinite;
}
.page-not-found .particle:nth-child(17) {
  top: 63.6144578313%;
  left: 84.4660194175%;
  font-size: 30px;
  -webkit-filter: blur(0.34px);
          filter: blur(0.34px);
  -webkit-animation: 29s float infinite;
          animation: 29s float infinite;
}
.page-not-found .particle:nth-child(18) {
  top: 96.3855421687%;
  left: 60.1941747573%;
  font-size: 30px;
  -webkit-filter: blur(0.36px);
          filter: blur(0.36px);
  -webkit-animation: 39s float2 infinite;
          animation: 39s float2 infinite;
}
.page-not-found .particle:nth-child(19) {
  top: 64.039408867%;
  left: 19.7628458498%;
  font-size: 12px;
  -webkit-filter: blur(0.38px);
          filter: blur(0.38px);
  -webkit-animation: 28s float2 infinite;
          animation: 28s float2 infinite;
}
.page-not-found .particle:nth-child(20) {
  top: 0.9852216749%;
  left: 98.814229249%;
  font-size: 12px;
  -webkit-filter: blur(0.4px);
          filter: blur(0.4px);
  -webkit-animation: 28s float infinite;
          animation: 28s float infinite;
}
.page-not-found .particle:nth-child(21) {
  top: 30.2070645554%;
  left: 54.8481880509%;
  font-size: 21px;
  -webkit-filter: blur(0.42px);
          filter: blur(0.42px);
  -webkit-animation: 24s float infinite;
          animation: 24s float infinite;
}
.page-not-found .particle:nth-child(22) {
  top: 72.3716381418%;
  left: 14.7347740668%;
  font-size: 18px;
  -webkit-filter: blur(0.44px);
          filter: blur(0.44px);
  -webkit-animation: 24s floatReverse infinite;
          animation: 24s floatReverse infinite;
}
.page-not-found .particle:nth-child(23) {
  top: 88.6723507917%;
  left: 85.2105778648%;
  font-size: 21px;
  -webkit-filter: blur(0.46px);
          filter: blur(0.46px);
  -webkit-animation: 26s floatReverse infinite;
          animation: 26s floatReverse infinite;
}
.page-not-found .particle:nth-child(24) {
  top: 20.4379562044%;
  left: 85.1272015656%;
  font-size: 22px;
  -webkit-filter: blur(0.48px);
          filter: blur(0.48px);
  -webkit-animation: 34s float2 infinite;
          animation: 34s float2 infinite;
}
.page-not-found .particle:nth-child(25) {
  top: 62.0689655172%;
  left: 85.9683794466%;
  font-size: 12px;
  -webkit-filter: blur(0.5px);
          filter: blur(0.5px);
  -webkit-animation: 27s floatReverse infinite;
          animation: 27s floatReverse infinite;
}
.page-not-found .particle:nth-child(26) {
  top: 29.9879081016%;
  left: 60.3700097371%;
  font-size: 27px;
  -webkit-filter: blur(0.52px);
          filter: blur(0.52px);
  -webkit-animation: 26s float infinite;
          animation: 26s float infinite;
}
.page-not-found .particle:nth-child(27) {
  top: 91.7874396135%;
  left: 81.7120622568%;
  font-size: 28px;
  -webkit-filter: blur(0.54px);
          filter: blur(0.54px);
  -webkit-animation: 34s floatReverse2 infinite;
          animation: 34s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(28) {
  top: 17.3493975904%;
  left: 60.1941747573%;
  font-size: 30px;
  -webkit-filter: blur(0.56px);
          filter: blur(0.56px);
  -webkit-animation: 26s float2 infinite;
          animation: 26s float2 infinite;
}
.page-not-found .particle:nth-child(29) {
  top: 5.8679706601%;
  left: 84.4793713163%;
  font-size: 18px;
  -webkit-filter: blur(0.58px);
          filter: blur(0.58px);
  -webkit-animation: 22s float infinite;
          animation: 22s float infinite;
}
.page-not-found .particle:nth-child(30) {
  top: 5.8536585366%;
  left: 24.5098039216%;
  font-size: 20px;
  -webkit-filter: blur(0.6px);
          filter: blur(0.6px);
  -webkit-animation: 36s floatReverse2 infinite;
          animation: 36s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(31) {
  top: 30.1703163017%;
  left: 56.7514677104%;
  font-size: 22px;
  -webkit-filter: blur(0.62px);
          filter: blur(0.62px);
  -webkit-animation: 36s floatReverse infinite;
          animation: 36s floatReverse infinite;
}
.page-not-found .particle:nth-child(32) {
  top: 29.0205562273%;
  left: 86.6601752678%;
  font-size: 27px;
  -webkit-filter: blur(0.64px);
          filter: blur(0.64px);
  -webkit-animation: 24s float infinite;
          animation: 24s float infinite;
}
.page-not-found .particle:nth-child(33) {
  top: 73.607748184%;
  left: 43.8596491228%;
  font-size: 26px;
  -webkit-filter: blur(0.66px);
          filter: blur(0.66px);
  -webkit-animation: 35s float2 infinite;
          animation: 35s float2 infinite;
}
.page-not-found .particle:nth-child(34) {
  top: 62.5152625153%;
  left: 86.3591756624%;
  font-size: 19px;
  -webkit-filter: blur(0.68px);
          filter: blur(0.68px);
  -webkit-animation: 26s floatReverse infinite;
          animation: 26s floatReverse infinite;
}
.page-not-found .particle:nth-child(35) {
  top: 69.5226438188%;
  left: 15.732546706%;
  font-size: 17px;
  -webkit-filter: blur(0.7px);
          filter: blur(0.7px);
  -webkit-animation: 34s floatReverse2 infinite;
          animation: 34s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(36) {
  top: 28.3965728274%;
  left: 42.2812192724%;
  font-size: 17px;
  -webkit-filter: blur(0.72px);
          filter: blur(0.72px);
  -webkit-animation: 29s float2 infinite;
          animation: 29s float2 infinite;
}
.page-not-found .particle:nth-child(37) {
  top: 82.8258221681%;
  left: 58.7659157689%;
  font-size: 21px;
  -webkit-filter: blur(0.74px);
          filter: blur(0.74px);
  -webkit-animation: 21s float2 infinite;
          animation: 21s float2 infinite;
}
.page-not-found .particle:nth-child(38) {
  top: 55.6776556777%;
  left: 97.1540726202%;
  font-size: 19px;
  -webkit-filter: blur(0.76px);
          filter: blur(0.76px);
  -webkit-animation: 30s floatReverse infinite;
          animation: 30s floatReverse infinite;
}
.page-not-found .particle:nth-child(39) {
  top: 93.0909090909%;
  left: 23.4146341463%;
  font-size: 25px;
  -webkit-filter: blur(0.78px);
          filter: blur(0.78px);
  -webkit-animation: 33s floatReverse2 infinite;
          animation: 33s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(40) {
  top: 43.4032059186%;
  left: 32.6409495549%;
  font-size: 11px;
  -webkit-filter: blur(0.8px);
          filter: blur(0.8px);
  -webkit-animation: 30s float infinite;
          animation: 30s float infinite;
}
.page-not-found .particle:nth-child(41) {
  top: 74.8481166464%;
  left: 23.4604105572%;
  font-size: 23px;
  -webkit-filter: blur(0.82px);
          filter: blur(0.82px);
  -webkit-animation: 23s float infinite;
          animation: 23s float infinite;
}
.page-not-found .particle:nth-child(42) {
  top: 72.3763570567%;
  left: 38.8726919339%;
  font-size: 29px;
  -webkit-filter: blur(0.84px);
          filter: blur(0.84px);
  -webkit-animation: 39s float2 infinite;
          animation: 39s float2 infinite;
}
.page-not-found .particle:nth-child(43) {
  top: 48.602673147%;
  left: 43.0107526882%;
  font-size: 23px;
  -webkit-filter: blur(0.86px);
          filter: blur(0.86px);
  -webkit-animation: 23s floatReverse2 infinite;
          animation: 23s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(44) {
  top: 6.7714631197%;
  left: 25.3164556962%;
  font-size: 27px;
  -webkit-filter: blur(0.88px);
          filter: blur(0.88px);
  -webkit-animation: 21s float2 infinite;
          animation: 21s float2 infinite;
}
.page-not-found .particle:nth-child(45) {
  top: 52.7472527473%;
  left: 73.6015701668%;
  font-size: 19px;
  -webkit-filter: blur(0.9px);
          filter: blur(0.9px);
  -webkit-animation: 36s floatReverse infinite;
          animation: 36s floatReverse infinite;
}
.page-not-found .particle:nth-child(46) {
  top: 38.7878787879%;
  left: 47.8048780488%;
  font-size: 25px;
  -webkit-filter: blur(0.92px);
          filter: blur(0.92px);
  -webkit-animation: 24s float infinite;
          animation: 24s float infinite;
}
.page-not-found .particle:nth-child(47) {
  top: 8.8779284834%;
  left: 50.4451038576%;
  font-size: 11px;
  -webkit-filter: blur(0.94px);
          filter: blur(0.94px);
  -webkit-animation: 23s floatReverse infinite;
          animation: 23s floatReverse infinite;
}
.page-not-found .particle:nth-child(48) {
  top: 70.4156479218%;
  left: 24.557956778%;
  font-size: 18px;
  -webkit-filter: blur(0.96px);
          filter: blur(0.96px);
  -webkit-animation: 31s floatReverse infinite;
          animation: 31s floatReverse infinite;
}
.page-not-found .particle:nth-child(49) {
  top: 53.2687651332%;
  left: 76.0233918129%;
  font-size: 26px;
  -webkit-filter: blur(0.98px);
          filter: blur(0.98px);
  -webkit-animation: 38s floatReverse2 infinite;
          animation: 38s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(50) {
  top: 1.9583843329%;
  left: 51.1307767945%;
  font-size: 17px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-animation: 40s floatReverse infinite;
          animation: 40s floatReverse infinite;
}
.page-not-found .particle:nth-child(51) {
  top: 15.5339805825%;
  left: 61.5234375%;
  font-size: 24px;
  -webkit-filter: blur(1.02px);
          filter: blur(1.02px);
  -webkit-animation: 30s floatReverse infinite;
          animation: 30s floatReverse infinite;
}
.page-not-found .particle:nth-child(52) {
  top: 88.1355932203%;
  left: 61.4035087719%;
  font-size: 26px;
  -webkit-filter: blur(1.04px);
          filter: blur(1.04px);
  -webkit-animation: 37s float infinite;
          animation: 37s float infinite;
}
.page-not-found .particle:nth-child(53) {
  top: 53.2019704433%;
  left: 39.5256916996%;
  font-size: 12px;
  -webkit-filter: blur(1.06px);
          filter: blur(1.06px);
  -webkit-animation: 35s float infinite;
          animation: 35s float infinite;
}
.page-not-found .particle:nth-child(54) {
  top: 96.6183574879%;
  left: 0.9727626459%;
  font-size: 28px;
  -webkit-filter: blur(1.08px);
          filter: blur(1.08px);
  -webkit-animation: 32s float2 infinite;
          animation: 32s float2 infinite;
}
.page-not-found .particle:nth-child(55) {
  top: 2.9020556227%;
  left: 93.4761441091%;
  font-size: 27px;
  -webkit-filter: blur(1.1px);
          filter: blur(1.1px);
  -webkit-animation: 31s float infinite;
          animation: 31s float infinite;
}
.page-not-found .particle:nth-child(56) {
  top: 89.4289185905%;
  left: 84.0664711632%;
  font-size: 23px;
  -webkit-filter: blur(1.12px);
          filter: blur(1.12px);
  -webkit-animation: 33s float2 infinite;
          animation: 33s float2 infinite;
}
.page-not-found .particle:nth-child(57) {
  top: 57.2121212121%;
  left: 87.8048780488%;
  font-size: 25px;
  -webkit-filter: blur(1.14px);
          filter: blur(1.14px);
  -webkit-animation: 34s floatReverse infinite;
          animation: 34s floatReverse infinite;
}
.page-not-found .particle:nth-child(58) {
  top: 29.0909090909%;
  left: 57.5609756098%;
  font-size: 25px;
  -webkit-filter: blur(1.16px);
          filter: blur(1.16px);
  -webkit-animation: 22s float infinite;
          animation: 22s float infinite;
}
.page-not-found .particle:nth-child(59) {
  top: 75.6363636364%;
  left: 46.8292682927%;
  font-size: 25px;
  -webkit-filter: blur(1.18px);
          filter: blur(1.18px);
  -webkit-animation: 23s float infinite;
          animation: 23s float infinite;
}
.page-not-found .particle:nth-child(60) {
  top: 76.1904761905%;
  left: 53.974484789%;
  font-size: 19px;
  -webkit-filter: blur(1.2px);
          filter: blur(1.2px);
  -webkit-animation: 22s float2 infinite;
          animation: 22s float2 infinite;
}
.page-not-found .particle:nth-child(61) {
  top: 22.4390243902%;
  left: 21.568627451%;
  font-size: 20px;
  -webkit-filter: blur(1.22px);
          filter: blur(1.22px);
  -webkit-animation: 35s floatReverse infinite;
          animation: 35s floatReverse infinite;
}
.page-not-found .particle:nth-child(62) {
  top: 48.4261501211%;
  left: 91.6179337232%;
  font-size: 26px;
  -webkit-filter: blur(1.24px);
          filter: blur(1.24px);
  -webkit-animation: 38s floatReverse2 infinite;
          animation: 38s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(63) {
  top: 85.5407047388%;
  left: 0.9775171065%;
  font-size: 23px;
  -webkit-filter: blur(1.26px);
          filter: blur(1.26px);
  -webkit-animation: 33s floatReverse infinite;
          animation: 33s floatReverse infinite;
}
.page-not-found .particle:nth-child(64) {
  top: 96.5517241379%;
  left: 14.8221343874%;
  font-size: 12px;
  -webkit-filter: blur(1.28px);
          filter: blur(1.28px);
  -webkit-animation: 35s floatReverse infinite;
          animation: 35s floatReverse infinite;
}
.page-not-found .particle:nth-child(65) {
  top: 39.119804401%;
  left: 52.0628683694%;
  font-size: 18px;
  -webkit-filter: blur(1.3px);
          filter: blur(1.3px);
  -webkit-animation: 39s float2 infinite;
          animation: 39s float2 infinite;
}
.page-not-found .particle:nth-child(66) {
  top: 74.6928746929%;
  left: 29.5857988166%;
  font-size: 14px;
  -webkit-filter: blur(1.32px);
          filter: blur(1.32px);
  -webkit-animation: 33s float2 infinite;
          animation: 33s float2 infinite;
}
.page-not-found .particle:nth-child(67) {
  top: 63.768115942%;
  left: 67.1206225681%;
  font-size: 28px;
  -webkit-filter: blur(1.34px);
          filter: blur(1.34px);
  -webkit-animation: 40s floatReverse2 infinite;
          animation: 40s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(68) {
  top: 46.1916461916%;
  left: 91.7159763314%;
  font-size: 14px;
  -webkit-filter: blur(1.36px);
          filter: blur(1.36px);
  -webkit-animation: 22s floatReverse2 infinite;
          animation: 22s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(69) {
  top: 29.0205562273%;
  left: 89.5813047712%;
  font-size: 27px;
  -webkit-filter: blur(1.38px);
          filter: blur(1.38px);
  -webkit-animation: 33s float infinite;
          animation: 33s float infinite;
}
.page-not-found .particle:nth-child(70) {
  top: 8.6746987952%;
  left: 30.0970873786%;
  font-size: 30px;
  -webkit-filter: blur(1.4px);
          filter: blur(1.4px);
  -webkit-animation: 38s floatReverse2 infinite;
          animation: 38s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(71) {
  top: 53.4629404617%;
  left: 87.9765395894%;
  font-size: 23px;
  -webkit-filter: blur(1.42px);
          filter: blur(1.42px);
  -webkit-animation: 37s float2 infinite;
          animation: 37s float2 infinite;
}
.page-not-found .particle:nth-child(72) {
  top: 76.0975609756%;
  left: 61.7647058824%;
  font-size: 20px;
  -webkit-filter: blur(1.44px);
          filter: blur(1.44px);
  -webkit-animation: 32s floatReverse2 infinite;
          animation: 32s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(73) {
  top: 31.2958435208%;
  left: 57.9567779961%;
  font-size: 18px;
  -webkit-filter: blur(1.46px);
          filter: blur(1.46px);
  -webkit-animation: 29s float infinite;
          animation: 29s float infinite;
}
.page-not-found .particle:nth-child(74) {
  top: 19.4884287454%;
  left: 72.4779627816%;
  font-size: 21px;
  -webkit-filter: blur(1.48px);
          filter: blur(1.48px);
  -webkit-animation: 31s float2 infinite;
          animation: 31s float2 infinite;
}
.page-not-found .particle:nth-child(75) {
  top: 35.5117139334%;
  left: 59.3471810089%;
  font-size: 11px;
  -webkit-filter: blur(1.5px);
          filter: blur(1.5px);
  -webkit-animation: 27s float2 infinite;
          animation: 27s float2 infinite;
}
.page-not-found .particle:nth-child(76) {
  top: 16.52490887%;
  left: 71.3587487781%;
  font-size: 23px;
  -webkit-filter: blur(1.52px);
          filter: blur(1.52px);
  -webkit-animation: 36s float2 infinite;
          animation: 36s float2 infinite;
}
.page-not-found .particle:nth-child(77) {
  top: 30.243902439%;
  left: 65.6862745098%;
  font-size: 20px;
  -webkit-filter: blur(1.54px);
          filter: blur(1.54px);
  -webkit-animation: 37s float2 infinite;
          animation: 37s float2 infinite;
}
.page-not-found .particle:nth-child(78) {
  top: 26.0554885404%;
  left: 35.9572400389%;
  font-size: 29px;
  -webkit-filter: blur(1.56px);
          filter: blur(1.56px);
  -webkit-animation: 26s floatReverse2 infinite;
          animation: 26s floatReverse2 infinite;
}
.page-not-found .particle:nth-child(79) {
  top: 22.2760290557%;
  left: 73.0994152047%;
  font-size: 26px;
  -webkit-filter: blur(1.58px);
          filter: blur(1.58px);
  -webkit-animation: 22s float2 infinite;
          animation: 22s float2 infinite;
}
.page-not-found .particle:nth-child(80) {
  top: 40.7272727273%;
  left: 57.5609756098%;
  font-size: 25px;
  -webkit-filter: blur(1.6px);
          filter: blur(1.6px);
  -webkit-animation: 34s floatReverse2 infinite;
          animation: 34s floatReverse2 infinite;
}
@-webkit-keyframes apparition {
  from {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes apparition {
  from {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes float {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(180px);
            transform: translateY(180px);
  }
}
@keyframes float {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(180px);
            transform: translateY(180px);
  }
}
@-webkit-keyframes floatReverse {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-180px);
            transform: translateY(-180px);
  }
}
@keyframes floatReverse {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-180px);
            transform: translateY(-180px);
  }
}
@-webkit-keyframes float2 {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
  }
}
@keyframes float2 {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
  }
}
@-webkit-keyframes floatReverse2 {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
  }
}
@keyframes floatReverse2 {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
  }
}